import { _httpClient } from "@/api/connection";

export const DatosPersonales = {

    /**
     * Devuelve una promesa que contiene un objeto con los datos personales del usuario
     * @returns {Promise<DatoPerfilObtenerDTO>}
     */
    obtener: async () => {
        try {
            return await _httpClient.get(`/verify`, {})
        } catch (e) {
            throw e
        }
    },
    finalizarSesion: async () => {
        try {
            return await _httpClient.get(`/logout`, {})
        } catch (e) {
            throw e
        }
    },
    obtenerSucursal:async () => {
        try {
            return await _httpClient.get(`/sucursal`)
        } catch (e) {
            throw e
        }
    }


}
