import {useSsoStore} from "@/store/modules/sso.store";

export default {
    path: "/cuenta",
    name: "Cuenta",
    meta: {
        title: "Cuenta",
    },
    children: [
        {
            path: "adeudo",
            name: "Adeudo",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/account/Adeudo.vue"),

        },
        {
            path: "anticipo",
            name: "Anticipo",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/account/Anticipo.vue"),

        },
        {
            path: "client",
            name: "Cliente",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/client/ListCliente"),

        },
    ]
}