import axios from "axios";

let localAddress = "http://127.0.0.1:18080/WebPrintSDK/";
let connectionMode = "http:";  // Puedes usar esta variable si es necesaria
let printer = 'Printer1';
const _baseUrl = process.env.VUE_APP_IMPRESORA;
const _baseNAMEUrl = process.env.VUE_APP_API_NAME;

const printerApi = axios.create({
    baseURL: _baseUrl,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',

    },
    withCredentials: true,
});
const getGenero = axios.create({
    baseURL: "https://api.genderize.io",
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',

    },
    withCredentials: true,
});
export { printer, printerApi, getGenero };
