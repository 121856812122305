import {_httpClient} from "@/api/connection";
import {printaSexo} from "@/helpers/Tickets/Peticion";
import axios from "axios";

export const UsuarioService = {

    /**
     * Para generar el token del usuario, no es posible generarlo si no ha sido verificado
     * @returns {Promise<TokenDTO>}
     */
    login: async (email, password) => { //al realizar el logueo se le debe asignar un numero de cuenta/sesion

        try {
            await _httpClient.get("/sanctum/csrf-cookie");

            const user= {
                email:email,
                password:password
            }
            return await _httpClient.post(`/admin/login`, user)
        } catch (e) {
            throw e
        }
    },

    renovarSesion: async () => {
        try {
            const usuario = await _httpClient.get(`Usuarios/RenovarToken`)
            return usuario
        } catch (e) {
            throw e
        }
    },

    obtenerModulos: async () => {
        try {
            return await _httpClient.post('Usuarios/Modulos/ObtenerModulos')
        } catch (e) {
            throw e
        }
    },
    validarSesion: async () =>{
        try {
            return await _httpClient.get('verify',{})
        } catch (e) {
            throw e
        }
    },
    determinarSexo: async (nombre) => {
        try {
          return await printaSexo(nombre)
        } catch (error) {
           // console.error("Error al determinar el género:", error);
            throw error;
        }
    },

}
