import {printerApi,getGenero} from "@/api/connection/Impresora";
import NotificacionError from "@/helpers/notifications/NotificacionError";

const _baseUrl = process.env.VUE_APP_IMPRESORA
let localAddress = "http://127.0.0.1:18080/WebPrintSDK/";
let connectionMode = "http:";
let printer = 'Printer1';

//todo: Crear el servicio para realizar la peticion si lo lograras serás un gran crack
export const getServer = async (ticket) => {
    let localAddress = "http://127.0.0.1:18080/WebPrintSDK/";
    try {
        const resp = await printerApi.post(localAddress + printer, ticket, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                withCredentials: false,
            }
        );
        return resp;
    } catch (e) {
        await NotificacionError.mostrar("Ocurrió un error al intentar imprimir el ticket, por favor revise el estado de la impresora y reimprima el ticket");
    }
}
export const printaSexo = async (name) => {
    try {
        const resp = await getGenero.get(`?name=${name}&country_id=MX`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                withCredentials: false,
            }
        );
        return resp;
    } catch (e) {
        await NotificacionError.mostrar("Ocurrió un error al intentar imprimir el ticket, por favor revise el estado de la impresora y reimprima el ticket");
    }
}