export default {
    path: "/compras",
    name: "Compras",
    meta: {
        title: "Compras",
    },
    children:[
        {
            path: "detalle",
            name: "Compras",
            children: [
                {
                    path: "mascota",
                    name: "MASCOTA",
                    component: () => import(/* webpackPrefetch: true */"@/views/modules/reception/components/HistorialMascota"),
                },
                {
                    path: "cliente",
                    name: "Cliente",
                    component: () => import(/* webpackPrefetch: true */"@/views/modules/reception/components/HistorialCliente"),
                    meta: {
                        title: "Historial Cliente",

                    }
                },

            ]

        },
        {
            path: "comision",
            name: "Comision",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/reception/components/Comision.vue"),
        },
        {
            path: "store",
            name: "Store",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/Sales/Ventas"),
        } ,
        {
            path: "account",
            name: "Cuentas",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/Sales/Cuenta"),
        },
        {
            path: "pagos",
            name: "Payment",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/Sales/Pagos"),
        },

    ]
}