export default {
    path: "/getproducto",
    name: "GetProducto",
    meta: {
        title: "Productos",
    },
    children: [
        {
            path: "order",
            name: "Order",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/products/Productos"),

        },
        {
            path: "price",
            name: "Precios",
            component: () => import("@/views/modules/admin/products/Precio"),
        }
    ]
}