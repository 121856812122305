import {useSsoStore} from "@/store/modules/sso.store";

export default {
    path: "/catalagos",
    name: "Catalago",
    meta: {
        title: "Catalago",
    },
    children: [
        {
            path: "esquema",
            name: "Catalago",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/medical/catalogues/EsquemaGrafico.vue"),

        },
        {
            path: "grafico",
            name: "Grafico",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/medical/catalogues/EsquemaImagen.vue"),

        },
        {
            path: "razas",
            name: "Razas",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/medical/catalogues/Razas"),

        },
    ]
}