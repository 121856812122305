module.exports = {
    publicPath: '/',
    pwa: {
        name: "Sistemas LUCRAF",
        themeColor: "#9F2241",
    },
    devServer: {
        proxy: {
            '/api': {
                target: 'https://api.genderize.io',
                changeOrigin: true,
                pathRewrite: { '^/api': '' },
            },
        },
    },
}
