export default {
    path: "/caducidad",
    name: "Caducidad",
    meta: {
        title: "Caducidades",
    },
    children: [
        {
            path: "caducidad",
            name: "Caducidad",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/products/caducidad/Caducidad"),

        },
        {
            path: "caducidades",
            name: "Caducidades",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/products/caducidad/ListCaducidades"),

        },
        {
            path: "desfase",
            name: "Desfase",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/products/caducidad/Desfase"),

        },
        {
            path: "existencia",
            name: "Existencia",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/products/inventory/Existencia"),
            meta: {
                title: "Existencias",
            },
        },
        {
            path: "sucursal",
            name: "Sucursal",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/products/inventory/ExistenciaProduct.vue"),
            meta: {
                title: "Existencias de la sucursal",
            },
        },
    ]
}