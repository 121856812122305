export default {
    path:"/getcliente",
    meta: {
        title:"Get Cliente"
    },
    name:'verCliente',
    children:[
        {
            path     : "",
            name     : "GetCliente",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/client/Cliente"),

            meta     : {
                title: "Cliente",
            },
            children:[
                {
                    path     : "cuentas",
                    name     : "listCuentas",
                    component: () => import(/* webpackPrefetch: true */"@/views/modules/client/components/Cuenta"),

                    meta     : {
                        title: "List Cuentas",
                    },
                },
                {
                    path     : "pagos",
                    name     : "listPagos",
                    component: () => import(/* webpackPrefetch: true */"@/views/modules/client/components/Pagos"),

                    meta     : {
                        title: "List Pagos",
                    },
                },
                {
                    path     : "productos",
                    name     : "listProductos",
                    component: () => import(/* webpackPrefetch: true */"@/views/modules/client/components/Productos"),

                    meta     : {
                        title: "List Productos",
                    },
                },
                {
                    path     : "Servicios",
                    name     : "listServicios",
                    component: () => import(/* webpackPrefetch: true */"@/views/modules/client/components/Servicios"),

                    meta     : {
                        title: "List Servicios",
                    },
                },
                {
                    path     : "listVencimiento",
                    name     : "listVencimiento",
                    component: () => import(/* webpackPrefetch: true */"@/views/modules/client/components/Vencimiento"),

                    meta     : {
                        title: "List Mascotas",
                    },
                },
            ]
        },
    ]
}